.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.btn-group{
  margin: 5px 0;
}

.react-datepicker-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
}

.pager{
  margin: 5px 0;
}
.show-grid {
  border: 1px solid;
  margin-bottom: 15px;
}