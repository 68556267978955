.NewSimulation form {
    padding-bottom: 15px;
}

.react-datepicker-wrapper {
    margin: 5px 0;
    padding: 0;
    width: 100%;
}

.form-group {
    display: table;
    width: 100%;
}